<template>
    <div>
      <p class="font-weight-bold mt-4 mb-2">Riwayat Validasi</p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Tanggal<br/>Reject/Approve</th>
              <th scope="col">Petugas<br/>Reject/Approve</th>
              <th scope="col">Status</th>
              <th scope="col">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="history in data[0].approval_histories" :key="history.id"> 
              <td>{{ formatDate(history.tanggal_uji) }}</td>
              <td>{{ history.user_name }}</td>
              <td>{{ history.status }}</td>
              <td v-html="history.keterangan"></td>
              <td>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  <script>
  import moment from "moment";
  
  export default {
    props: {
      data: {
        type: Array,
        required: true,
      },
    },
    methods: {
      formatDate(timestamp, format) {

        if (timestamp == 0) {
          return "-"
        }

        const momentObj = moment.unix(timestamp);
        const humanDate = momentObj.format('DD-MM-YYYY');
        return humanDate;
      },
      getStatus(val) {
        if (val == 1) {
          return "Disetujui"
        } else if (val == 2) {
          return "Ditolak"
        } else if (val == 0) {
          return "Menunggu"
        }
      }
    },
  };
  </script>
  