<template>
  <Modal
    :show="showModal"
    modalClasses="modal-dialog-scrollable"
    headerClasses="pb-0"
    @close="onCloseModal"
    size="lg"
  >
    <template v-slot:header>
      <div class="modal-title pb-4">
        <p class="mdl-title">Validasi Uji Konektifitas</p>
      </div>
    </template>
    <div class="px-4 border-top">
      <div>
        <label>Status</label>
        <base-input name="Status" rules="required">
          <el-select v-model="action">
            <el-option
              v-for="option in optionValue"
              :key="option.val"
              :label="option.name"
              :value="option.name"
            ></el-option>
          </el-select>
        </base-input>

        <div >
          <label>Tanggal Pengujian</label>

          <!-- Tanggal Pengujian -->
          <div class="form-group row">
            <div class="col-lg-12">
              <base-input class="m-0" rules="required" name="Tanggal Pengujian">
                <flat-picker
                  name="Tanggal Pengujian"
                  rules="required"
                  slot-scope="{ focus, blur }"
                  @on-open="focus"
                  @on-close="blur"
                  :config="provideSelect.flatPickerConfig"
                  class="form-control datepicker"
                  v-model="tanggalPengujian"
                ></flat-picker>
              </base-input>
            </div>
          </div>


          <div v-if="action == 'Diterima'">
            <label>UID </label>
            <div class="row" v-for="(item, idx) in data[0].loggers" :key="idx">
              <div class="col-lg-8">
                <base-input
                  name="uid"
                  :placeholder="`isi manual logger ${idx + 1}`"
                  v-model="generateUidValues[idx]"
                ></base-input>
              </div>
              <div class="col-lg-4">
                <button class="btn btn-primary" @click="getUID(idx)">
                  <small>Membuat Kode Otomatis</small>
                </button>
              </div>
            </div>
  
            <small class="text-warning">Anda dapat membuat UID manual , atau dapat menggunakan generate code</small>
          </div>
        </div>

          <label class="mt-4">Keterangan</label>
          <html-editor v-model="verifyNote"></html-editor>
       

        <div class="form-group row px-3 float-right pb-4">
          <button class="btn btn-primary" @click="onSubmit">KIRIM</button>
        </div>
      </div>

      <ValidationManualReport :data="data" />

      <div>
        <div class="form-group row px-3 float-right pb-4 mt-4">
          <button class="btn btn-warning" @click="onCloseModal">Tutup</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from "axios";
import API from "../../../api/base_url.js";
//   import defaults from "@/util/defaults";
import Modal from "@/components/Modal.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
import { BaseInput } from "@/components";
import ValidationManualReport from "../../Tables/ValidationManualReport.vue";
//   import ValidationHistory from "./ValidationHistory.vue";

export default {
  components: {
    ValidationManualReport,
    Modal,
    HtmlEditor,
    flatPicker,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
    //   ValidationHistory,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tanggalPengujian: "",
      generateUidValues: {},
      generateUID: "",
      action: "",
      histories: [],
      verifyNote: "",
      optionValue: [
        {
          name: "Diterima",
          val: 1,
        },
        {
          name: "Ditolak",
          val: 2,
        },
      ],
      form: {
        keterangan: null,
        tanggal_uji: null,
        status: null,
        user_id: null,
        uid: null,
        petugas: null,
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          defaultHour: 0,
        },
      },
    };
  },
  methods: {
    getUID(idx) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      API.get(`uji-konek/uuid`, { headers })
        .then(({ data }) => {
          // this.generateUidValues[idx] = data
          this.$set(this.generateUidValues, idx, data);
          console.log(this.generateUidValues[idx], "------>");
        })
        .catch((err) => {
          Swal.fire({ icon: "error", text: `Gagal Mendapatkan UID` });
        });
    },
    onSubmit() {
      if (!this.action) {
        Swal.fire({
          icon: "error",
          text: `Data anda belum lengkap`,
        });
      } else {
        let loggerIdTmp = [];
        let uidTmp = [];

        if (this.data[0].loggers !== undefined) {
          for (let i = 0; i < this.data[0].loggers.length; i++) {
            loggerIdTmp.push(this.data[0].loggers[i].id);
          }

          for (const key in this.generateUidValues) {
            if (this.generateUidValues.hasOwnProperty(key)) {
              const value = this.generateUidValues[key];
              uidTmp.push(value);
            }
          }
        }

        this.generateUidValues = {};

        let payload = {
          keterangan: this.verifyNote,
          logger_ids: loggerIdTmp,
          logger_uids: uidTmp,
          status: this.action,
          tanggal_uji: this.tanggalPengujian,
        };




        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

 
        API.put(`uji-konek/${this.data[1]}/approval`, payload, { headers })
          .then(({ data }) => {
            if (this.action == "Diterima") {
              Swal.fire({
                icon: "success",
                text: `Validasi telah diterima`,
              });
            } else if (this.action == "Ditolak") {
              Swal.fire({icon: "success",text: `Validasi telah ditolak`});
            }
            this.resetPayload();
            this.$emit("close");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: `Gagal`,
            });
            console.log(err);
          });
      }
    },
    resetPayload() {
      this.action = "";
      this.verifyNote = "";
      this.tanggalPengujian = "";
    },
    // async onSubmit() {
    //   const auth = JSON.parse(localStorage.getItem("auth-data"));
    //   this.form.user_id = auth.name;
    //   const tempValidate = await this.$refs.formValidator.validate();
    //   if (!tempValidate) {
    //     Swal.fire({
    //         icon: 'error',
    //         text: `Data belum lengkap`,
    //       });
    //   } else {
    //     const data = await axios.post(`${defaults.baseURL}/api/v1/ujikonek/validasi/${this.data.id}`, this.form);
    //     if (data.status === 200) {
    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Tersimpan',
    //         text: `Data telah tersimpan`,
    //       }).then(() => {
    //         this.$emit("close");
    //       });
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Gagal',
    //         text: `Silakan Coba Kembali`,
    //      });
    //     }
    //   }
    // },
    onCloseModal() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.mdl-title {
  font-size: 18px;
  font-weight: 600;
  color: #6c757d;
  width: 100%;
}
</style>
