<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pendaftaran Uji Konek</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
            <base-button @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>
  
      <div v-if="listData.length == 0">
        <h1>Loading ...</h1>
      </div>
      <div v-else class="container-fluid mt--6">
        <div class="row justify-content-center mb--4">
          <div  class="col">
            <div class="card">
              <div class="card-header">
                <h6 class="surtitle">Rincian</h6>
                <h5 class="h3 mb-0">Uji Konektivitas</h5>
              </div>
              <div class="card-body">
                <ul class="list-group">
                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>ID Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ listData[0].id }}</span>
                          </div>
                        </div>
                    </li>

                    

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Tanggal Uji</strong>
                          </div>
                          <div class="col-lg-9">
                            <!-- {{ listData[0].tanggal_uji }} -->
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <strong>Status Pendaftaran</strong>
                          </div>
                          <div class="col-lg-9">
                            <span :style="getStyleObject(listData[0].status)" class="text-white p-2 text-center rounded">
                              {{ listData[0].status }}
                            </span>
                          </div>
                        </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat pada</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ formatDate(listData[0].created_at) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData[0].comp.comp_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData[0].comp.siup_pj }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Lampiran SIUP</strong>
                        </div>
                        <div class="col-lg-9">
                          <a
                            :href="listData[0].comp.siup_file_id"
                            target="blank">
                            <span>
                              <i class="ni ni-cloud-download-95 text-primary"></i>
                            </span>
                            <span>
                              {{ listData[0].comp.siup_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Alamat Perusahaan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData[0].comp.comp_address}}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Provinsi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData[0].comp.province_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kabupaten / Kota</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData[0].comp.kabkot_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Telepon Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData[0].comp.comp_phone }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Kantor</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData[0].comp.comp_email }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData[0].comp.user_name }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nomor Handphone Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData[0].comp.user_phone }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Email Penanggung Jawab Uji</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{  listData[0].comp.user_email }}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>

            <!-- LOGGER -->
            <div class="card" v-if="listData[0].loggers !== undefined">
              <div class="card-header">
                <h1 class="m-0">Data Logger</h1>
              </div>
              <div class="card-body" v-for="(logger, index) in listData[0].loggers" :key="index">
                <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-12">
                          <span class="badge rounded-pill bg-warning text-white">Data Logger #{{ index + 1}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>UID</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.uid }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Brand</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.brand }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Model / Type</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ logger.type }}</span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
            </div>

            <!-- SENSOR -->
            <div class="card" v-if="listData[0].loggers !== undefined">
              <div class="card-header">
                  <h1 class="m-0">Data Sensor</h1>
              </div>
              <div class="card-body" v-for="(item, index) in listData[0].sensors" :key="index">
                <div v-if="item.length !== 0">
                  <ul class="list-group" v-for="(items,indexs) in item" :key="indexs">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-12">
                          <span class="badge rounded-pill bg-warning text-white">Sensor - {{ item.sensor_type}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-10">
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brand</strong>
                          </div>
                          <div class="col-10">{{ items.brand }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Probe</strong>
                          </div>
                          <div class="col-10">{{ items.probe }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Range Pengukuran</strong>
                          </div>
                          <div class="col-10">
                            <div class="row">
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Minimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ items.min_measurement }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="row">
                                  <div class="col-12">
                                    <strong>Maksimal</strong>
                                  </div>
                                  <div class="col-12">
                                    {{ items.max_measurement }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Pengukuran</strong>
                          </div>
                          <div class="col-10">{{ items.measurement_method}}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Jadwal Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ items.calibration_schedule }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Metode Kalibrasi</strong>
                          </div>
                          <div class="col-10">{{ items.calibration_method }}</div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Brochure File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="`https://${items.brochure_file_id}`"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ (items.brochure_file_id ? "File Terupload" : 'Tidak ada' )}}</span>
                              </a>
                          </div>
                        </div>
  
                        <div class="row mb-2">
                          <div class="col-2">
                            <strong>Support File</strong>
                          </div>
                          <div class="col-10">
                             <a
                                :href="`https://${items.support_file_id}`"
                                target="_blank">
                                <span>
                                  <i class="ni ni-cloud-download-95 text-primary"></i>
                                </span>
                                <span>
                                  {{ items.support_file_id ? "File Terupload" : 'Tidak ada' }}</span>
                              </a>
                          </div>
                        </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                
              </div>
            </div>

            <div class="row justify-content-end position-fixed right-0" style="top:50%">
              <base-button @click="validasiHandler" class="mx-3 mb-4" type="danger">
                <!-- {{ modalData.isValidated ? "Riwayat Validasi" : "Validasi" }} -->
                Validasi
              </base-button>
            </div>
          </div>
        </div>
      </div>
      <ModalValidasi v-if="showModal" :data="[listData[0], this.$route.params.id]" :show-modal="showModal" @close="onCloseModal" />
    </div>
  </template>
  <script>
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import ModalValidasi from "../ModalValidasi.vue";
  import dummyUjiKonek from "../../../Tables/AdminTable/dummyUjiKonek";
  import API from "../../../../api/base_url.js"
  import moment from  'moment'
  
  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      ModalValidasi
    },
    mounted() {
      console.log(this.$route.params.id);

      let result = dummyUjiKonek.filter(data => data.id_pendaftaran == this.$route.params.id)
      this.listData = result
      console.log(result);

      this.getData()
      // this.getDataLogger()
      // this.getDataSensor()
      // this.getRiwayat()
    },
    data() {
      return {
        listDataLoggerCount : 0,
        listData : [],
        listDataLogger: [],
        listDataSensor: {},
        listRiwayat: [],
        showModal:false,
      };
    },
    methods: {
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`uji-konek/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listData = [content.data]
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      getStyleObject(val) {
        let backgroundColor;
        switch (val) {
          case "Menunggu Validasi":
            backgroundColor = "rgb(245 158 11)";
            break;
          case "Diterima":
            backgroundColor = "rgb(20 83 45)";
            break;
          case "Draft":
            backgroundColor = "rgb(147 147 147)";
            break;
          case "Ditolak":
            backgroundColor = "#dc3545";
            break;
          default:
            backgroundColor = "white";
        }

        return {
          backgroundColor,
          // Anda dapat menambahkan properti CSS lainnya di sini
        };
      },

      formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
      },
      getDataSensor() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/uji-konektivitas/sensors?uji_konektivitas_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listDataSensor = content.data
        })
        .catch((err) => {
          console.log(err);
        })
      },
      validasiHandler() {
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
        this.getData();
      },
      back() {
        this.$router.push("/verifikasi/uji-konektivitas");
      },
      
      
      getRiwayat() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/uji-konektivitas/histories?uji_konektivitas_id=${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          if (content.data == null) {
          this.listRiwayat = []
          } else {
            this.listRiwayat = content.data
          }
        })
        .catch((err) => {
          console.log(err);
        })
      }
    },
  };
  </script>
  <style></style>
  